<template>
  <v-list-item :active-class="`primary white--text`" @click="openSupport">
    <v-list-item-icon>
      <v-icon>support_agent</v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title>Teknik Destek</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  methods: {
    openSupport() {
      let chatWrap = document.getElementById("chat-wrap");
      if (chatWrap) {
        chatWrap.style.display = "block";
        document.getElementById("chat-header").click();
      }
    }
  }
};
</script>
