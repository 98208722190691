<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :expand-on-hover="expandOnHover"
    mobile-breakpoint="960"
    width="270"
    floating
    app
    v-bind="$attrs"
  >
    <div style="width: 260px;">
      <v-list dense nav>
        <v-list-item>
          <img width="180px" src="@/assets/logo2-big.png" />
        </v-list-item>
      </v-list>
      <v-list shaped>
        <!-- Style cascading bug  -->
        <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
        <div />

        <template v-for="(item, i) in computedItems">
          <template v-if="hasRole(item.role)">
            <base-item :key="`item-${i}`" :item="item" />
          </template>
        </template>
        <TechnicalSupport />

        <!-- Style cascading bug  -->
        <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
        <div />
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import TechnicalSupport from "@/components/TechnicalSupport";

export default {
  name: "DashboardCoreDrawer",

  components: { TechnicalSupport },

  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "dashboard",
        to: "/"
      },
      {
        icon: "supervised_user_circle",
        title: "vendor.salesmen",
        role: "ROLE_VENDOR",
        to: "/salesmen"
      },
      {
        icon: "mdi-account",
        title: "my-students",
        role: ["ROLE_SALESMAN", "ROLE_VENDOR"],
        to: "/my-students"
      },
      {
        icon: "replay",
        title: "webinar-records",
        to: { name: "student-webinar" }
      },
      {
        icon: "video_library",
        title: "archive-videos",
        to: { name: "student-archive" }
      },
      {
        icon: "content_paste",
        title: "quizzes",
        to: { name: "student-quiz" }
      },
      {
        icon: "list_alt",
        title: "exams",
        to: "/student/exams"
      },
      {
        icon: "mdi-account",
        title: "user",
        role: "ROLE_ADMIN",
        to: "/users"
      },
      {
        icon: "video_call",
        title: "demo-webinar",
        role: ["ROLE_ADMIN", "ROLE_SALESMAN", "ROLE_VENDOR", "ROLE_STUDIO"],
        to: "/webinar/61/join" // Hardcoded
      },
      {
        icon: "mdi-poll",
        title: "leaderboard.title",
        role: "ROLE_USER",
        to: "/leaderboard"
      },
      {
        icon: "mdi-toy-brick-outline",
        title: "course.title",
        role: "ROLE_ADMIN",
        to: "/courses"
      },
      {
        icon: "mdi-google-classroom",
        title: "courseCategories.title",
        role: "ROLE_ADMIN",
        to: "/course-categories"
      },
      {
        icon: "mdi-forum",
        title: "quiz.title",
        role: "ROLE_ADMIN",
        to: "/quizzes"
      },
      {
        icon: "mdi-ticket",
        title: "badge.title",
        role: "ROLE_ADMIN",
        to: "/badges"
      },
      {
        icon: "mdi-help-circle",
        title: "qa.title",
        role: "ROLE_USER",
        to: "/qa/my"
      },
      {
        icon: "leaderboard",
        title: "report.user",
        role: "ROLE_USER",
        to: { name: "user-report" }
      },
      {
        icon: "mdi-sparkles",
        title: "interactive",
        role: "ROLE_USER",
        to: { name: "expertiments-list" }
      },
      {
        icon: "mdi-help-circle",
        title: "qa.all-title",
        role: "ROLE_ADMIN",
        to: "/qa/all"
      },
      {
        icon: "mdi-newspaper",
        title: "announcements.title",
        role: "ROLE_ADMIN",
        to: "/announcements"
      }
    ]
  }),

  computed: {
    ...mapGetters("auth", ["currentUser"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      }
    },
    computedItems() {
      return this.items.map(this.mapItem);
    }
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t("menu." + item.title)
      };
    },
    hasRole(role) {
      if (!role) return true;
      if (!this.currentUser.roles) return false;
      if (Array.isArray(role)) return this.checkRoleList(role);

      return this.currentUser.roles.find(user_role => {
        return role === user_role;
      });
    },
    checkRoleList(roleList) {
      return this.currentUser.roles.find(user_role => {
        if (roleList.includes(user_role)) return true;
      });
    }
  }
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
